import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation, Link } from "react-router-dom";
import { useVerifyOtpMutation, useSendOtpMutation } from "../../../../../Store/Reducers/apiReducer";
import send from "../../../../../Assets/img/success-icon.svg";
import VerificationInput from "react-verification-input";
import './ConfirmEmail.scss';
import Loader from "../../../../../Shared/Loader";
import axios from 'axios';
import { getStorage } from "../../../../../Utilities/storage";
import { useDispatch, useSelector } from "react-redux";
import { resetAuthState } from "../../../../../Store/Reducers/authReducer";

const ConfirmEmail = () => {

  const [otp, setOtp] = useState("");
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const email = searchParams.get('user-email');
  const [verifyOtp, { isLoading: isLoading, error: isError }] = useVerifyOtpMutation();
  const [sendOtp, { isLoading: isLoadingsend, error: isErrorsend }] = useSendOtpMutation();
  const navigate = useNavigate();
  var userInfo = getStorage("userInfo");
  const dispatch = useDispatch();
  const { REACT_APP_API_URL } = process.env;
  useEffect(() => {
    const checkUserInfo = async () => {
      if (userInfo?.user_info?.email_verified_at && userInfo?.user_info?.email_verified_at !== null || !email) {
        navigate('/');
      }

    };

    checkUserInfo();
  }, [userInfo, navigate]);

  const handleOtpChange = (value) => {
    setOtp(value);
  };


  const handleSubmit = async () => {
    if (otp.length < 6) {
      alert("OTP must be 6 digits long.");
      return;
    }

    try {
      const emailVerify = await verifyOtp({
        email: email,
        otp: otp,
      });

      if (emailVerify?.data?.code === 200) {
        setSuccessMessage("Email verified successfully!");

        let userInfo = getStorage("userInfo");

        if (userInfo) {

          const token = userInfo.token;
          const tokenNo = token.split("|");

          try {
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `${REACT_APP_API_URL}/web/logout`,
              headers: {
                MobileToken: 1234565,
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenNo[1]}`, // Use the extracted token
              },
            };
            const response = await axios.request(config);
            dispatch(resetAuthState());
            localStorage.removeItem("userInfo");
            window.location.href = "/login";
          } catch (error) {
            console.error("Error during logout:", error);
          }
        } else {
          window.location.href = "/login";
        }
      } else {

        alert("Failed to verify OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      alert("An error occurred while verifying the OTP. Please try again.");
    }
  };


  const handleSendOtp = async () => {
    setLoading(true);
    setError(null);
    const formData = new FormData();
    formData.append('email', email);

    try {
      const response = await axios.post('https://webapi.laundramoon.com/api/send-otp', formData, {
        headers: {
          Accept: 'application/json',
          MobileToken: '1234565', // Add your MobileToken here
        },
      });

      if (response?.data?.code === 200) {
        alert('OTP has been sent to your email!');
      } else {
        alert('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setError('Failed to send OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  const handleLogout = async () => {
    var userInfo = getStorage("userInfo");
    userInfo = userInfo.token;
    var tokenNo = userInfo.split("|");

    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${REACT_APP_API_URL}/web/logout`,
        headers: {
          MobileToken: 1234565,
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenNo[1]}`,
        },
      };
      const response = await axios.request(config);
      dispatch(resetAuthState());
      localStorage.removeItem("userInfo");
      window.location.href = "/login";
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };


  return (
    <>
      <div className="confirm-email-container">
        <img src={send} className="confirm-email-image" alt="Send Message Icon" />
        <div className="confirm-email-text">
          <p className="confirm-email-heading">We sent an OTP to your email</p>
          <p className="confirm-email-description">
            Please verify your account by entering the OTP code sent to <strong>{email}</strong>.
          </p>
        </div>
        {error && (
          <p className="error-message" style={{ color: 'red' }}>
            {error}
          </p>
        )}
        {isError && (
          <p className="error-message" style={{ color: 'red' }}>
            {isError?.data?.data?.otp || "Failed to verify OTP"}
          </p>
        )}
        {successMessage && (
          <p className="success-message" style={{ color: 'green' }}>
            {successMessage}
          </p>
        )}
        <div className="verification-input-container">
          <VerificationInput
            length={6}
            value={otp}
            onChange={handleOtpChange}
            placeholder=""
            classNames={{
              container: "verification-input",
              character: "verification-input input"
            }}
          />
          <button
            className="confirm-email-button"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? <Loader /> : 'Verify Account'}
          </button>

          {/* {isSuccess && <p className="success-message">OTP verified successfully!</p>} */}
        </div>
        <div className="resend-container">
          <p>If you haven’t received the email, please wait for 10 minutes and then request it again.</p>
          <div className="resend-button">
            <button onClick={handleSendOtp} disabled={loading}>
              {loading ? 'Sending...' : 'Resend email'}
            </button>
          </div>

        </div>
      </div>
    </>
  );
};

export default ConfirmEmail;
