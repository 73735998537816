import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({  title,
  description,
  type,
  url,
  imageUrl,
  keywords}) => {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta
          head-key="description"
          name="description"
          content={description}
        />
        <meta name="robots" content="nofollow" />
        <link rel="canonical" href="https://www.laundramoon.com" />
        <meta head-key="keywords" name="keywords" content={keywords} />
        {/* End standard metadata tags */}
        {/* Facebook tags */}
        <meta head-key="og:type" property="og:type" content={type} />
        <meta head-key="og:title" property="og:title" content={title} />
        <meta
          head-key="og:description"
          property="og:description"
          content={description}
        />

        <meta head-key="og:url" property="og:url" content={url} />
        <meta head-key="og:image" property="og:image" content={imageUrl} />
        {/* End Facebook tags */}
        {/* Twitter tags */}
        <meta head-key="twitter:card" name="twitter:card" content={type} />
        <meta
          head-key="twitter:image"
          name="twitter:image"
          content={imageUrl}
        />
        <meta
          head-key="twitter:title"
          name="twitter:title"
          content={title}
        />
        <meta
          head-key="twitter:description"
          name="twitter:description"
          content={description}
        />

      </Helmet>
    </div>
  );
};

export default SEO;
