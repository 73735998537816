import { createSlice } from "@reduxjs/toolkit";

export const ProductSlice = createSlice({
  name: "product",
  initialState: {
    product: [],
    service_id: ''
  },
  reducers: {
    getProductByServiceID: (state, action) => {
      state.service_id = action?.payload;
    },
    emptyProduct: (state, action) => {
      state.product = [];
    },
    getProducts: (state, action) => {
      const { id } = action.payload.service;
      const existingService = state.product?.find(service => service?.service?.id === id);
      if (!existingService) {
        state.product?.push({ ...action.payload });
      }
    },
    incrementQty: (state, action) => {
      const { service, articals } = action?.payload;
      const existingService = state?.product?.find((s) => s?.service.id === service?.id);
      if (existingService) {
        const existingArticle = existingService?.articals.find((item) => item?.id === articals?.id);
        if (existingArticle) {
          existingArticle.quantity++;
        }
      }
    },
    decrementQty: (state, action) => {
      const { service, articals } = action.payload;
      const existingServiceIndex = state.product.findIndex((s) => s?.service?.id === service?.id);
      if (existingServiceIndex !== -1) {
        const existingArticleIndex = state.product[existingServiceIndex]?.articals?.findIndex((item) => item?.id === articals?.id);
        if (existingArticleIndex !== -1) {
          const existingArticle = state.product[existingServiceIndex].articals[existingArticleIndex];
          if (existingArticle.quantity === 1) {
            state.product[existingServiceIndex].articals.splice(existingArticleIndex, 1);
          } else {
            existingArticle.quantity--;
          }
          if (state.product[existingServiceIndex].articals.length === 0) {
            state.product.splice(existingServiceIndex, 1);
          }
        }
      }
    },
  }
})

export const { getProductByServiceID, getProducts,emptyProduct ,incrementQty, decrementQty } = ProductSlice.actions;
export default ProductSlice.reducer;