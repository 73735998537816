import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "./App.scss";
import "./index.css";
import FallbackLoader from "./Shared/FallbackLoader/FallbackLoader.jsx";
import ScrollToTop from "./Shared/ScrollToTop.jsx";
import SEO from "./Shared/SEO/SEO.jsx";
import ConfirmEmail from "./Views/Website/Checkout/Components/Signup/ConfirmEmail.jsx";
const Business = lazy(() => import("./Views/Website/Business/Business.jsx"));
const Home = lazy(() => import("./Views/Website/Home/Home.jsx"));
const PrivacyPolicy = lazy(() => import("./Views/Website/Privacy/PrivacyPolicy.jsx"));
const Layout = lazy(() => import("./Layout/Layout.jsx"));
const Contact = lazy(() => import("./Views/Website/Contact/Contact.jsx"));
const Blog = lazy(() => import("./Views/Website/Blog/Blog.jsx"));
const BlogDetail = lazy(() => import("./Views/Website/Blog/BlogDetail/BlogDetail.jsx"));
const About = lazy(() => import("./Views/Website/About/About.jsx"));
const ServicesDetail = lazy(() => import("./Views/Website/Services/ServicesDetail/ServicesDetail.jsx"));
const Location = lazy(() => import("./Views/Website/Location/Location.jsx"));
const FAQs = lazy(() => import("./Views/Website/FAQs/FAQs.jsx"));
const Pricing = lazy(() => import("./Views/Website/Pricing/Pricing.jsx"));
const Checkout = lazy(() => import("./Views/Website/Checkout/Checkout.jsx"));
const Login = lazy(() => import("./Views/Website/Auth/Login.jsx"));
const UserLayout = lazy(() => import("./Layout/UserLayout.jsx"));
const MyOrder = lazy(() => import("./Views/User/MyOrder/MyOrder.jsx"));
const MyAccount = lazy(() => import("./Views/User/MyAccount/MyAccount.jsx"));


function App() {
  return (
    <Suspense fallback={<FallbackLoader />}>
      <BrowserRouter>
        <SEO />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogDetail />} />
            <Route path="/about" element={<About />} />
            <Route path="/business" element={<Business />} />
            <Route path="/service/:serviceName" element={<ServicesDetail />} />
            <Route path="/location" element={<Location />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/pricing/:slug/pricelist" element={<Pricing />} />
            <Route path="/london/:slug" element={<Location />} />
            <Route path="/booking" element={<Checkout />} />
            <Route path="/email-verify" element={<ConfirmEmail />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/booking/:step" element={<Checkout />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>

          {/* Protected Routes */}
          <Route path="/dashboard" element={<UserLayout />}>
            <Route path="myorder" element={<MyOrder />} />
            <Route path="myaccount" element={<MyAccount />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
