import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    orderType: "time-saver",
    orderID:null,
    address: {
      postcode: null,
      lat: null,
      lng: null,
      city: null,
      state: null,
      address: null,
      country: null,
      FlatApartmentNo: null,
      HotelApartmentNo: null,
      addressType: "Home",
    },
    collection: {
      collectionDetails: {
        date: null,
        time: null,
        message: "",
        notes: "Pickup is not available on sunday",
      },
      deliveryDetails: {
        date: null,
        time: null,
        message: "",
        notes: "Pickup is not available on sunday",
      },
      frequency: "once",
    },
    userDetails: null,
    payment: null,
  },
  reducers: {
    setOrderType: (state, action) => {
      state.orderType = action.payload;
    },
    setOrderID: (state, action) => {
      state.orderID = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    clearAddress: (state) => {
      state.address = {
        postcode: null,
        lat: null,
        lng: null,
        city: null,
        state: null,
        address: null,
        country: null,
        FlatApartmentNo: null,
        HotelApartmentNo: null,
        addressType: "Home",
      };
    },
    clearCollection: (state) => {
      state.collection = {
        collectionDetails: {
          date: null,
          time: null,
          message: "",
          notes: "Pickup is not available on sunday",
        },
        deliveryDetails: {
          date: null,
          time: null,
          message: "",
          notes: "Pickup is not available on sunday",
        },
        frequency: "once",
      };
    },
    setCollectionDetailsDate: (state, action) => {
      state.collection.collectionDetails.date = action.payload;
    },
    setCollectionDetailsTime: (state, action) => {
      state.collection.collectionDetails.time = action.payload;
    },
    setCollectionDetailsMessage: (state, action) => {
      state.collection.collectionDetails.message = action.payload;
    },
    setCollectionDetailsNotes: (state, action) => {
      state.collection.collectionDetails.notes = action.payload;
    },
    setDeliveryDetailsDate: (state, action) => {
      state.collection.deliveryDetails.date = action.payload;
    },
    setDeliveryDetailsTime: (state, action) => {
      state.collection.deliveryDetails.time = action.payload;
    },
    setDeliveryDetailsMessage: (state, action) => {
      state.collection.deliveryDetails.message = action.payload;
    },
    setDeliveryDetailsNotes: (state, action) => {
      state.collection.deliveryDetails.notes = action.payload;
    },
    setFrequency: (state, action) => {
      state.collection.frequency = action.payload;
    },
    setPayment: (state, action) => {
      state.payment = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
  },
});

export const { setAddress, clearAddress, clearCollection, setCollectionDetailsDate, setCollectionDetailsTime, setCollectionDetailsMessage, setCollectionDetailsNotes, setDeliveryDetailsDate, setDeliveryDetailsTime, setDeliveryDetailsMessage, 
  setDeliveryDetailsNotes, setFrequency, setOrderType, setPayment, setUserDetails,setOrderID } = orderSlice.actions;

export default orderSlice.reducer;
